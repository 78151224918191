// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import * as structureTypes from 'syft-acp-core/components/EditableTable'
import EditableTable from 'syft-acp-core/components/EditableTable'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import JobRolesSelector from './JobRoleSelector'

import { referenceStatuses } from './statuses'
import { orgTypes } from './orgTypes'

const getDataStructure = (roles, activeItem, updateActiveItem) => [
  ['company_name', { header: 'Company name', editable: true }],
  [
    'type_of_organization',
    {
      header: 'Type of organization',
      editable: true,
      type: structureTypes.TYPE_DROPDOWN,
      options: orgTypes,
      parameters: {
        allowAny: true,
        anyOption: 'No Type',
        ariaLabel: 'Type of organization',
        convertIds: false,
        categorized: true,
      },
    },
  ],
  [
    'job_role',
    {
      header: 'Job role',
      type: structureTypes.TYPE_CUSTOM,
      getData: () => (
        <div>
          <JobRolesSelector
            initialValue={activeItem.job_role}
            roles={roles}
            onChange={v => updateActiveItem('job_role', v)}
          />
        </div>
      ),
    },
  ],
  ['desc', { header: 'Job description', type: structureTypes.TYPE_TEXTAREA, editable: true }],
  ['reference_name', { header: 'Reference name', editable: true }],
  ['reference_email', { header: 'Reference email', editable: true }],
  ['reference_phone_number', { header: 'Reference tel nr.', editable: true }],
  [
    'reference_status',
    {
      header: 'Reference status',
      editable: true,
      type: structureTypes.TYPE_DROPDOWN,
      options: Object.keys(referenceStatuses).map(s => ({ id: s, label: referenceStatuses[s].name })),
      parameters: {
        ariaLabel: 'Reference status',
        anyOption: 'Reference status',
        convertIds: false,
      },
    },
  ],
  ['started', { header: 'Started (mm/yyyy)', editable: true }],
  ['ended', { header: 'Ended (mm/yyyy)', editable: true }],
]

class WorkerPrevExpEdit extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    updateActiveItem: PropTypes.func.isRequired,
    activeItem: PropTypes.object,
    roles: PropTypes.array.isRequired,
  }

  static defaultProps = {
    isLoading: false,
    activeItem: {},
  }

  render() {
    // TODO: isLoading doesn't actually do anything when passed on.
    const { activeItem, isLoading, updateActiveItem, roles } = this.props
    const isSaved = false
    const structure = getDataStructure(roles, activeItem, updateActiveItem)

    return (
      <div>
        <RecordSegment withTable>
          <EditableTable
            data={activeItem}
            isSaved={isSaved}
            isEditable={!isLoading}
            structure={structure}
            update={updateActiveItem}
          />
        </RecordSegment>
      </div>
    )
  }
}

export default WorkerPrevExpEdit
