import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row, Alert } from 'react-bootstrap'
import moment from 'moment'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import entityDetail from 'syft-acp-util/entityDetail'
import SetFilter from 'syft-acp-core/components/SetFilter'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import EntityAlert from 'syft-acp-core/components/Alerts/EntityAlert'
import * as appealsActions from 'syft-acp-core/store/appeals/actions'
import * as filterActions from 'syft-acp-core/store/filters/actions'
import * as workerStrikesActions from 'syft-acp-core/store/workerStrikes/actions'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import { getWorkerStrike$ } from 'syft-acp-core/store/workerStrikes/selectors'
import { getWorker$ } from 'syft-acp-core/store/workers/selectors'

import WorkerStrikesList from '../WorkerStrikes/WorkerStrikesList'
import AppealDetailsControls from './AppealDetailsControls'
import { AppealDetailProps as Props } from './AppealDetail.types'
import { dataStructure } from './AppealDetail.constants'
import { trackingEvents } from './AppealDetail.tracking'

export const storeConnector = connect(
  state => {
    const strikeID = Number(state.routing.locationBeforeTransitions.query.strikeID)
    const workerID = Number(state.routing.locationBeforeTransitions.query.workerID)

    return {
      workerID,
      strikeID,
      strike: getWorkerStrike$(state)(strikeID),
      worker: getWorker$(state.workers)(workerID),
    }
  },
  dispatch => ({
    actions: bindActionCreators(
      { ...appealsActions, ...filterActions, ...workerStrikesActions, ...workersActions },
      dispatch,
    ),
  }),
)

export const AppealDetail = ({
  actions,
  actionUpdate,
  data,
  id,
  lastBody,
  isPristine,
  strikeID,
  workerID,
  strike,
  worker,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const isNew = id === 0
  const isClosed = isPristine && !!data.outcome
  const isStrikeOld = strike?.activated_at && moment().diff(moment(strike.activated_at), 'hours') >= 72

  // When creating a new appeal we must hydrate the UI with worker and strike data from separate requests
  const finalData = { ...data }
  if (isNew) {
    finalData.strike = {
      id: strike?.id,
      activated_at: strike?.activated_at || '',
      internal_notes: strike?.internal_notes || '',
      strike_reason: strike?.strike_reason,
    }
    // @ts-expect-error - not all data is available
    finalData.worker = {
      id: worker.id,
      full_name: worker.full_name,
      active_strikes_count: worker.strikes_count,
    }
  }

  useEffect(() => {
    if (isNew && workerID && strikeID) {
      actions.fetchWorkerStrike(workerID, strikeID, {})
      actions.fetchWorker(workerID)
    } else actions.fetchAppeal(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    triggerEvent(trackingEvents.PAGE.VIEWED, { appeal_id: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SetFilter queryKey="exclude_never_activated" disabled={isNew} />
      <SetFilter queryKey="paginated" disabled={isNew} />
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <AppealDetailsControls id={id} data={finalData} isPristine={isPristine} />
              <EntityAlert lastBody={lastBody} />
              {isNew && isStrikeOld && (
                <Alert bsStyle="warning">
                  Please be aware that you are creating an appeal for a strike which was created more than 72
                  hours ago.
                </Alert>
              )}
              {dataStructure(isNew, isClosed).map(({ title, structure }) => (
                <RecordSection
                  key={title}
                  title={title}
                  data={finalData}
                  structure={structure}
                  update={actionUpdate}
                />
              ))}
              {!isNew && (
                <WorkerStrikesList
                  workerID={finalData.worker?.id}
                  highlightID={finalData.strike?.id}
                  isAppealsView
                />
              )}
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    </>
  )
}

export default storeConnector(entityDetail('appeals', AppealDetail))
