// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import NotifyBar from 'syft-acp-atoms/NotifyBar'
import RecordSection from 'syft-acp-core/components/RecordSection'
import WorkerPrevExp from 'syft-acp-core/components/WorkerPrevExp'
import WorkerHeader from 'syft-acp-core/entities/WorkerDetail/WorkerHeader'
import BanWorkerModal from 'syft-acp-core/components/Modal/BanWorkerModal'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import entityDetail from 'syft-acp-util/entityDetail'

import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { dataStructure } from 'syft-acp-core/entities/WorkerDetail/structure'
import { WorkerStrikeDetailsProps as Props } from './WorkerOnboardingDetail.types'
import { trackingEvents } from './WorkerOnboardingDetail.tracking'
import WorkerDetailControls from 'syft-acp-core/entities/WorkerDetail/WorkerDetailControls'

import { useInterviewStructure, useRtwStructure } from './WorkerOnboardingDetail.hooks'

const WorkerOnboardingDetail = ({
  id,
  data,
  userEmail,
  actionUpdate,
  isPristine,
  actions,
  lastMessage = '',
}: Props) => {
  const [emptyInitialTrainingDetails] = useState(data?.training_details === null)
  const triggerEvent = useTrackingTrigger()

  useEffect(() => {
    const needsUpdate = !data || !data.id || !data.bio || !data.us_i9_form
    const workerID = get(data, 'id', id)
    if (needsUpdate) {
      actions.fetchWorker(workerID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const workerName = get(data, 'full_name', '(unknown)')

  const onPrevExpSaved = (itemId: number) => {
    if (itemId !== 0) {
      triggerEvent(trackingEvents.PREV_EXPERIENCE.UPDATED, {
        prev_exp_id: itemId,
      })
    } else {
      triggerEvent(trackingEvents.PREV_EXPERIENCE.CREATED)
    }
  }

  const onPrevExpDeleted = (itemId: number) => {
    triggerEvent(trackingEvents.PREV_EXPERIENCE.DELETED, {
      prev_exp_id: itemId,
    })
  }

  const rtwStructure = useRtwStructure(id)
  const [interviewTitle, interviewStructure] = useInterviewStructure()

  return (
    <>
      <WorkerDetailControls data={data} isPristine={isPristine} />

      <NotifyBar conditional noMargin visible={!!data.banned}>
        This user is currently banned from the system and cannot be assigned to do any jobs.
      </NotifyBar>

      <NotifyBar conditional noMargin visible={!!lastMessage}>
        {lastMessage}
      </NotifyBar>

      <WorkerHeader data={data} />
      <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
        <RecordSection
          title="Right to work"
          data={data}
          structure={rtwStructure}
          update={actionUpdate}
          fieldData={{ workerID: id, workerName }}
        />
        <RecordSection
          title="Driving licence"
          data={data}
          structure={dataStructure.drivingLicense}
          update={actionUpdate}
          fieldData={{ workerID: id, workerName }}
        />
      </CountrySpecific>
      <WorkerPrevExp workerID={id} onSave={onPrevExpSaved} onDelete={onPrevExpDeleted} />
      <RecordSection
        title={interviewTitle}
        data={data}
        structure={interviewStructure}
        update={actionUpdate}
        defaultData={
          data.training_details &&
          emptyInitialTrainingDetails && {
            interviewed_on: moment().format('YYYY-MM-DD'),
            interviewer: { email: userEmail },
          }
        }
      />
      <WorkerDetailControls data={data} isPristine={isPristine} />
      <BanWorkerModal userID={data.user_id} workerID={data.id} />
    </>
  )
}

export const storeConnector = connect(
  ({ auth }) => ({
    userEmail: `${get(auth, 'userData.email')}`,
  }),
  dispatch => ({
    actions: bindActionCreators(workersActions, dispatch),
  }),
)

export default storeConnector(entityDetail('workers', WorkerOnboardingDetail))
