// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isEqual } from 'lodash-es'

import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordHeaderForm from 'syft-acp-core/components/RecordSection/RecordHeaderForm'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { Button } from 'syft-acp-atoms/Button'

import WorkerPrevExpEdit from './WorkerPrevExpEdit'
import './WorkerPrevExpWrapper.css'

class WorkerPrevExpWrapper extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isValid: PropTypes.bool,
    isDeletable: PropTypes.bool,
    updateActiveItem: PropTypes.func.isRequired,
    saveActiveItem: PropTypes.func.isRequired,
    deleteActiveItem: PropTypes.func.isRequired,
    clearActiveItem: PropTypes.func.isRequired,
    activeItem: PropTypes.object,
    roles: PropTypes.array.isRequired,
  }

  static defaultProps = {
    isLoading: false,
    isDeletable: false,
    isValid: false,
    activeItem: {},
  }

  render() {
    const {
      isLoading,
      isDeletable,
      children,
      isValid,
      saveActiveItem,
      activeItem,
      clearActiveItem,
      deleteActiveItem,
      updateActiveItem,
      roles,
    } = this.props
    const isNewItem = isEqual(activeItem, {})
    const isExistingItem = !!activeItem.id
    return (
      <div className="WorkerPrevExpList">
        <ReactTooltip effect="solid" place="top" id="worker-prev-exp-list-tooltip" />
        <EntityDetailItem noMargin withPagination={false} withEntityList>
          <RecordSegment noPadding>
            <RecordHeaderForm>
              <LoadingIndicator loadingState={isLoading} />
              <Button kind="regular" disabled={isNewItem || isLoading} onClick={clearActiveItem}>
                New
              </Button>{' '}
              <Button kind="danger" disabled={!isDeletable || isLoading} onClick={deleteActiveItem}>
                Delete
              </Button>{' '}
              <Button kind="success" disabled={!isValid || isLoading} onClick={saveActiveItem}>
                Save
              </Button>
            </RecordHeaderForm>
            <RecordHeader main>
              {isExistingItem
                ? `Editing previous experience${activeItem.company_name || activeItem.job_role ? `: ` : ``}${[
                    activeItem.job_role,
                    activeItem.company_name,
                  ].join(' at ')}`
                : `Previous experience`}
            </RecordHeader>
          </RecordSegment>
          <RecordSegment noPadding>
            <WorkerPrevExpEdit
              isLoading={isLoading}
              roles={roles}
              activeItem={activeItem}
              updateActiveItem={updateActiveItem}
            />
          </RecordSegment>
          <RecordSegment noPadding>{children}</RecordSegment>
        </EntityDetailItem>
      </div>
    )
  }
}

export default WorkerPrevExpWrapper
