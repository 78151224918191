import { omit } from 'lodash-es'

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import { UpdateJobInput, RateWorkerInput, RatingDeleteInput } from '../types'

export const updateJob = ({ id, data }: UpdateJobInput) =>
  new Promise((resolve, reject) => {
    const skills = data?.skills || []
    const skillIDs = data.skill_ids || skills.map(skill => skill.id)
    const dataWithSkills = data?.skills ? { ...data, skill_ids: skillIDs } : data
    // Remove 'client_pay_rate' entirely in case it gets sent by the API.
    // <https://syftapp.atlassian.net/browse/CMS2-980>
    const reqArgs = { ...omit(dataWithSkills, ['client_pay_rate']) }
    const req = apiRequest({ path: `/jobs/${id}`, reqArgs, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })

export const rateWorker = ({ workerID, jobID, rate }: RateWorkerInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/jobs/${jobID}/workers/${workerID}/rating`,
      reqArgs: { stars: rate },
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })

export const ratingDelete = ({ jobID, workerID }: RatingDeleteInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/jobs/${jobID}/workers/${workerID}/rating`, method: 'DELETE' })
    handleCall(req, resolve, reject)
  })

export const fetchAdminJob = ({ payload }: { payload: { id: number } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/jobs/${payload.id}`,
      method: 'GET',
    })
    handleCall(req, resolve, reject)
  })
