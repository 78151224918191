/** Constants that represent our supported action type aliases. */
export const ENTITIES_FETCH_BEGIN = Symbol('ENTITIES_FETCH_BEGIN')
export const ENTITIES_FETCH_SUCCEEDED = Symbol('ENTITIES_FETCH_SUCCEEDED')
export const ENTITIES_FETCH_FAILED = Symbol('ENTITIES_FETCH_FAILED')
export const ENTITY_FETCH_BEGIN = Symbol('ENTITY_FETCH_BEGIN')
export const ENTITY_FETCH_SUCCEEDED = Symbol('ENTITY_FETCH_SUCCEEDED')
export const ENTITY_FETCH_FAILED = Symbol('ENTITY_FETCH_FAILED')
// TODO remove to conform to CRUD
export const ENTITY_SAVE_BEGIN = Symbol('ENTITY_SAVE_BEGIN')
export const ENTITY_SAVE_SUCCEEDED = Symbol('ENTITY_SAVE_SUCCEEDED')
export const ENTITY_SAVE_FAILED = Symbol('ENTITY_SAVE_FAILED')
export const ENTITY_MODIFY_BEGIN = Symbol('ENTITY_MODIFY_BEGIN')
export const ENTITY_MODIFY_SUCCEEDED = Symbol('ENTITY_MODIFY_SUCCEEDED')
export const ENTITY_MODIFY_FAILED = Symbol('ENTITY_MODIFY_FAILED')
//
export const ENTITY_CREATE_BEGIN = Symbol('ENTITY_CREATE_BEGIN')
export const ENTITY_CREATE_SUCCEEDED = Symbol('ENTITY_CREATE_SUCCEEDED')
export const ENTITY_CREATE_FAILED = Symbol('ENTITY_CREATE_FAILED')
export const ENTITY_UPDATE_BEGIN = Symbol('ENTITY_UPDATE_BEGIN')
export const ENTITY_UPDATE_SUCCEEDED = Symbol('ENTITY_UPDATE_SUCCEEDED')
export const ENTITY_UPDATE_FAILED = Symbol('ENTITY_UPDATE_FAILED')

export const ENTITY_DELETE_BEGIN = Symbol('ENTITY_DELETE_BEGIN')
export const ENTITY_DELETE_SUCCEEDED = Symbol('ENTITY_DELETE_SUCCEEDED')
export const ENTITY_DELETE_FAILED = Symbol('ENTITY_DELETE_FAILED')

export type EntitySet<T extends Record<string, any> = Record<string, any>> = {
  // Pagination items.
  totalPages: number | null
  totalWorkers: number | null
  totalBookings: number | null
  totalShifts: number | null
  totalVacancies: number | null
  total: number | null
  activePage: number | null
  perPage: number | null
  nextPage: number | null
  page: number | null
  // Other values.
  isLoadingData: boolean
  hasData: boolean
  ids: number[]
  // Note: this is always an empty object, unless localEntityMap is true.
  entityMap: Record<number, T>
}

export const emptySet: EntitySet<{}> = {
  // Pagination items.
  totalPages: null,
  totalWorkers: null,
  totalBookings: null,
  totalShifts: null,
  totalVacancies: null,
  total: null,
  activePage: null,
  perPage: null,
  nextPage: null,
  page: null,
  // Other values.
  isLoadingData: false,
  hasData: false,
  ids: [],
  // Note: this is always an empty object, unless localEntityMap is true.
  entityMap: {},
}
