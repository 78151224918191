export const trackingEvents = {
  LISTING_INFO: {
    PAGE: {
      VIEWED: 'listing_info.page.viewed',
    },
    VIEW_EMPLOYER: {
      CLICKED: 'listing_info.view_employer.clicked',
    },
    VIEW_LISTING_ON_PORTAL: {
      CLICKED: 'listing_info.view_listing_on_portal.clicked',
    },
    RESEND_APPROVAL: {
      CLICKED: 'listing_info.resend_approval.clicked',
    },
    BACK_TO_SHIFTS: {
      CLICKED: 'listing_info.back_to_shifts.clicked',
    },
    EMAIL_TIMESHEET: {
      CLICKED: 'listing_info.email_timesheet.clicked',
    },
    LISTING: {
      SAVED: 'listing_info.listing.saved',
      DELETED: 'listing_info.listing.deleted',
    },
    POST_LISTING_TO_AGENCY: {
      CLICKED: 'listing_info.post_listing_to_agency.clicked',
    },
    JOB: {
      SAVED: 'listing_info.job.saved',
    },
    JOB_STOP_OFFERS: {
      CLICKED: 'listing_info.job_stop_offers.clicked',
    },
    VENUE_PREFERENCES: {
      CLICKED: 'listing_info.venue_preferences.clicked',
    },
    PREFERENCES: {
      SAVED: 'listing_info.preferences.saved',
    },
    OFFER_TO_ALL: {
      CLICKED: 'listing_info.offer_to_all.clicked',
    },
    OFFER_TO_STAFF: {
      CLICKED: 'listing_info.offer_to_staff.clicked',
    },
    STOP_OFFERS: {
      CLICKED: 'listing_info.stop_offers.clicked',
    },
    DUPLICATE_SHIFT: {
      CLICKED: 'listing_info.duplicate_shift.clicked',
    },
    DELETE_SHIFT: {
      CLICKED: 'listing_info.delete_shift.clicked',
    },
    SHIFT: {
      SAVED: 'listing_info.shift.saved',
      DELETED: 'listing_info.shift.deleted',
      DUPLICATED: 'listing_info.shift.duplicated',
    },
    BOOKINGS: {
      SAVED: 'listing_info.bookings.saved',
      EDITED: 'listing_info.bookings.edited',
    },
    BOOKINGS_EDIT_ALL: {
      CLICKED: 'listing_info.bookings_edit_all.clicked',
    },
    AGENCY_BOOKING_SUBSECTION: {
      SAVED: 'listing_info.agency_booking_subsection.saved',
    },
    WORKER_MISSING_TIME: {
      ADDED: 'listing_info.worker_missing_time.added',
    },
  },
}
