// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('prevExp', {
  [entityTypes.ENTITY_FETCH_BEGIN]: types.PREV_EXP_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.PREV_EXP_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: types.PREV_EXP_FETCH_FAILED,
  [entityTypes.ENTITY_SAVE_BEGIN]: types.PREV_EXP_SAVE_BEGIN,
  [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.PREV_EXP_SAVE_SUCCEEDED,
  [entityTypes.ENTITY_SAVE_FAILED]: types.PREV_EXP_SAVE_FAILED,
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.PREV_EXP_LIST_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.PREV_EXP_LIST_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.PREV_EXP_LIST_FAILED,
})
