// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

// All possible values of the reference status field.
export const referenceStatuses = {
  filled_by_worker: {
    name: 'Filled by worker',
    color: '#5740b6',
  },
  email_sent: {
    name: 'Email sent',
    color: '#405ab6',
  },
  approved_by_referee: {
    name: 'Approved by referee',
    color: '#0bd536',
  },
  disapproved_by_referee: {
    name: 'Disapproved by referee',
    color: '#ff034a',
  },
  changed_by_referee: {
    name: 'Changed by referee',
    color: '#42a2ba',
  },
  set_never_worked_here_by_referee: {
    name: 'Never worked here',
    color: '#bb013a',
  },
  filled_by_recruiter: {
    name: 'Filled by recruiter',
    color: '#f607de',
  },
}
