import React, { useEffect, useState } from 'react'
import DataDropdown from 'syft-acp-core/components/EditableTable/DataDropdown'
import DataText from 'syft-acp-core/components/EditableTable/DataText'

const buildOptions = roles => {
  const roleOptions = roles.map(r => ({ id: r.label, label: r.label }))
  return [...roleOptions, { id: 'other', label: 'Other ( not in the list )' }]
}

const JobRoleSelector = ({ initialValue, roles, onChange }) => {
  const [currentValue, setCurrentValue] = useState('')
  const [currentCustomValue, setCurrentCustomValue] = useState('')
  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const value = currentValue === 'other' ? currentCustomValue : currentValue
  const options = buildOptions(roles)

  useEffect(() => {
    if (!initialValue) {
      setIsOtherSelected(false)
      setCurrentValue('')
      return
    }

    const valueInRoles = roles.map(r => r.label).includes(initialValue)
    if (valueInRoles) {
      setIsOtherSelected(false)
      setCurrentValue(initialValue)
    } else {
      setCurrentValue('other')
      setIsOtherSelected(true)
      setCurrentCustomValue(initialValue)
    }
  }, [initialValue, roles])

  useEffect(() => {
    onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleOnChange = v => {
    setCurrentValue(v)
    setIsOtherSelected(v === 'other')
    return false
  }

  return (
    <div>
      <DataDropdown
        name="job_role"
        value={currentValue}
        options={options}
        header="Job role"
        onChange={handleOnChange}
        editable
        parameters={{
          allowAny: true,
          anyOption: 'No job role',
          ariaLabel: 'Job Role',
          convertIds: false,
        }}
      />
      {isOtherSelected && (
        <DataText
          id="custom_job_role"
          value={currentCustomValue}
          parameters={{}}
          editable
          onChange={v => setCurrentCustomValue(v)}
        />
      )}
    </div>
  )
}

export default JobRoleSelector
