// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { showConfirmModal, showModal } from 'syft-acp-core/store/modals/actions'
import { store } from 'syft-acp-core/store'
import { unfilledAllocations, removeUnfilledAllocations } from './api'
import {
  AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN,
  AGENCY_UNFILLED_ALLOCATIONS_FETCH_SUCCEEDED,
  AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN,
  AGENCY_UNFILLED_ALLOCATIONS_DELETE_FAILED,
  AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED,
  AGENCY_UNFILLED_ALLOCATIONS_FETCH_FAILED,
} from './action-types'
import { deleteAgencyUnfilledAllocations } from './actions'
import { takeLatest, select, put } from 'redux-saga/effects'

export default function* saga() {
  yield takeLatestApiCall(AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN, unfilledAllocations)
  yield takeLatestApiCall(AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN, removeUnfilledAllocations)
  yield takeLatest(AGENCY_UNFILLED_ALLOCATIONS_FETCH_SUCCEEDED, function* (action: any) {
    // @ts-ignore
    const getUnfilledAllocations = yield select(state =>
      get(state, 'agencyUnfilledAllocations.entityMap.undefined', {})
    )
    // Selection Cascade Full Job dropdown option
    if (getUnfilledAllocations?.agencies_to_cascade_count === 0) {
      // Selection Cascade Job option for 0 cascade count
      yield put(
        showConfirmModal({
          modalHeader: 'No ranks remaining',
          question: 'There are no remaining ranks avaliable, remove allocation instead',
          onConfirm: () =>
            store.dispatch(deleteAgencyUnfilledAllocations([action?.options?.allocation_id], action?.request?.refetch)),
        })
      )
    } else if (action?.request?.cascadeFullJob) {
      yield put(showModal('cascadeFullModal', {}, null, { refetch: action?.request?.refetch }))
    } else {
      // Selection Cascade Job option for not 0 cascade count
      yield put(deleteAgencyUnfilledAllocations([action?.options?.allocation_id], action?.request?.refetch))
    }
  })
  yield takeLatest(AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED, function* (action: any) {
    if (action?.request?.refetch) {
      yield action.request.refetch()
    }
  })
  yield takeLatest(
    [
      AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED,
      AGENCY_UNFILLED_ALLOCATIONS_DELETE_FAILED,
      AGENCY_UNFILLED_ALLOCATIONS_FETCH_FAILED,
    ],
    notificationCall
  )
}
