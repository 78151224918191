// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function listPrevExp(workerID) {
  return { type: types.PREV_EXP_LIST_BEGIN, workerID }
}

export function deletePrevExp(prevExpID, workerID) {
  return { type: types.DELETE_PREV_EXP_BEGIN, prevExpID, workerID }
}

export function fetchPrevExp(workerID, prevExpID) {
  return { type: types.PREV_EXP_FETCH_BEGIN, workerID, prevExpID }
}

export function savePrevExp(prevExpID, workerID, data) {
  return { type: types.PREV_EXP_SAVE_BEGIN, prevExpID, workerID, data }
}
