// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeLatest } from 'redux-saga/effects'
import {
  DELETE_PREV_EXP_BEGIN,
  PREV_EXP_FETCH_BEGIN,
  PREV_EXP_LIST_BEGIN,
  PREV_EXP_SAVE_BEGIN,
} from 'syft-acp-core/actions/action-types'

import * as types from 'syft-acp-core/actions/action-types'
import * as api from 'syft-acp-core/api'
import { listPrevExp } from 'syft-acp-core/actions/previous-experience'
import { notificationCall } from './calls'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall(PREV_EXP_LIST_BEGIN, api.listPrevExp)
  yield takeLatestApiCall(PREV_EXP_FETCH_BEGIN, api.fetchPrevExp)
  yield takeLatestApiCall(PREV_EXP_SAVE_BEGIN, api.savePrevExp)
  yield takeLatestApiCall(DELETE_PREV_EXP_BEGIN, api.deletePrevExp)
  yield takeLatest([types.DELETE_PREV_EXP_SUCCEEDED, types.PREV_EXP_SAVE_SUCCEEDED], ({ request }) =>
    put(listPrevExp(request.workerID)),
  )
  yield takeLatest(
    [types.DELETE_PREV_EXP_SUCCEEDED, types.PREV_EXP_SAVE_SUCCEEDED, types.PREV_EXP_SAVE_FAILED],
    notificationCall,
  )
}
