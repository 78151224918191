// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import PhoneNumber from 'syft-acp-core/components/PhoneNumber'

import './Reference.css'

export default class Reference extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    tel: PropTypes.string,
  }

  static defaultProps = {
    name: null,
    email: null,
    tel: null,
  }

  render() {
    const { name, email, tel } = this.props
    const hasNothing = !name && !email && !tel
    if (hasNothing) {
      return <div className="reference-prev-exp">–</div>
    } else {
      return (
        <div className="reference-prev-exp">
          <span className={`item name ${!name ? 'no-value' : ''}`}>{name || 'No name given'}</span>
          <span className={`item email ${!email ? 'no-value' : ''}`}>
            {email || (tel ? 'No email given' : 'No email or tel nr. given')}
          </span>
          <PhoneNumber copyable={false} value={tel} />
        </div>
      )
    }
  }
}
