import React, { FC } from 'react'
import { Label } from 'react-bootstrap'
import classnames from 'classnames'

import './DataArrayItem.css'
import { DataArrayItemProps } from './DataArrayItem.types'

const DataArrayItem: FC<DataArrayItemProps> = ({ index, onDelete, children, className, title, rawTitle }) => (
  <Label className={classnames('DataArrayItem', 'tag', className)} bsStyle="default" title={title}>
    <span className="content">{children}</span>
    <button className="delete" aria-label={`Remove ${rawTitle} skill`} onClick={() => onDelete(index)}>
      ✕
    </button>
  </Label>
)

export default DataArrayItem
