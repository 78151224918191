// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get, noop } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectRolesOptions } from 'syft-acp-core/store/roles/selectors'
import { Button } from 'syft-acp-atoms/Button'
import config from 'syft-acp-core/config'

import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as notificationActions from 'syft-acp-core/actions/notifications'
import * as prevExpActions from 'syft-acp-core/actions/previous-experience'
import * as employerActions from 'syft-acp-core/store/employers/actions'
import * as employerTokenActions from 'syft-acp-core/actions/employer-tokens'
import { trackingEvents } from './tracking'

import WorkerPrevExpWrapper from './WorkerPrevExpWrapper'
import WorkerPrevExpList from './WorkerPrevExpList'
import { generateWorkerPortalUrl, getTokenOpenLogin } from 'syft-acp-core/entities/EmployerDetail/helpers'
import iconFactory from 'syft-acp-atoms/Icons'
import './WorkerPrevExp.css'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

const ClippyIcon = iconFactory('clippy')
const LinkExternalIcon = iconFactory('link-external')

const DEMO_EMPLOYERS = {
  local: {
    GB: 710512,
    US: 5369,
  },
  qa: {
    GB: 710512,
    US: 5369,
  },
  prod: {
    GB: 28788,
    US: 2240,
  },
}

const getDemoAccount = () => (DEMO_EMPLOYERS[config.stagingLevel] || {})[config.region]

/** Returns whether the arguments have any empty strings or null values. */
const hasEmpty = (...args) => args.filter(a => a == null || a === '').length > 0

/** Checks if all arguments are in MM/YYYY form. */
const hasInvalidMMYYYY = (...args) => args.filter(a => !/[0-9]{2}\/[0-9]{4}/.test(a)).length > 0

/** Checks whether the start month/year comes before the end. */
const startedComesFirst = (started, ended) => {
  const dateRe = /([0-9]{2})\/([0-9]{4})/
  const startedN = started.match(dateRe)
  const endedN = ended.match(dateRe)

  if (!startedN || !startedN[1] || !startedN[2] || !endedN || !endedN[1] || !endedN[2]) {
    // Invalid input.
    return null
  }
  // Check if started comes first.
  const startedVal = Number(startedN[2]) * 12 + Number(startedN[1])
  const endedVal = Number(endedN[2]) * 12 + Number(endedN[1])

  return startedVal <= endedVal
}

export const getListingOrWorkerInfoEvent = (isListingWorkerInfo, workerInfoEvent, listingInfoEvent) =>
  isListingWorkerInfo ? workerInfoEvent : listingInfoEvent

const TriggerEventComponent = ({ children }) => {
  const triggerEvent = useTrackingTrigger()
  return children({ triggerEvent })
}

class WorkerPrevExp extends PureComponent {
  static propTypes = {
    workerID: PropTypes.number.isRequired,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
  }

  static defaultProps = {
    onSave: noop,
    onDelete: noop,
  }

  constructor() {
    super()
    this.isSavingItem = false
    this.isDeletingItem = false
    this.state = {
      activeItem: {},
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Clear the form if we're just done saving or deleting an item.
    if (this.isSavingItem === true && nextProps.isSaving === false) {
      this.isSavingItem = false
      this.clearActiveItem()
    }
    if (this.isDeletingItem === true && nextProps.isLoading === false) {
      this.isDeletingItem = false
      this.clearActiveItem()
    }
  }

  /** Updates a field on the current item (when typing). */
  updateActiveItem = (path, str) => {
    const { activeItem } = this.state
    this.setState({ activeItem: { ...activeItem, [path]: str } })
  }

  /** Saves the currently active item. Either saves an existing item or creates a new one. */
  saveActiveItem = () => {
    const { workerID, actions, onSave } = this.props
    const { savePrevExp } = actions
    const { activeItem } = this.state

    if (!this.isValidNotify) {
      return false
    }

    // Save the current item. If we don't have an ID, use 0.
    this.isSavingItem = true
    const itemIdToSave = get(activeItem, 'id', 0)
    savePrevExp(itemIdToSave, workerID, { ...activeItem, worker_id: workerID })
    onSave(itemIdToSave)
  }

  /** Removes all data and creates a new, blank item. */
  clearActiveItem = () => {
    this.setState({ activeItem: {} })
  }

  /** Deletes the currently active item. */
  deleteActiveItem = () => {
    const { actions } = this.props
    actions.showConfirmModal({
      question: (
        <div>
          <p>Delete this previous experience item?</p>
        </div>
      ),
      onConfirm: this.deleteActiveItemConfirmed,
    })
  }

  deleteActiveItemConfirmed = () => {
    const { workerID, actions, onDelete } = this.props
    const { deletePrevExp } = actions
    const { activeItem } = this.state

    if (!this.isDeletableNotify) {
      return false
    }

    this.isDeletingItem = true
    const itemIdToDelete = get(activeItem, 'id', 0)
    deletePrevExp(itemIdToDelete, workerID)
    onDelete(itemIdToDelete)
  }

  /** Sets the form field to a specific existing item. */
  selectItem = expItem => {
    this.setState({ activeItem: expItem })
  }

  /** Returns whether the form's current state is valid for saving. */
  get isValid() {
    const { company_name, job_role, desc, started } = this.state.activeItem
    // Checks if any of these fields fields are empty.
    if (hasEmpty(company_name, job_role, desc, started)) {
      return false
    }
    return true
  }

  /** Returns whether we can save - this is done when clicking save, so we'll show error messages. */
  get isValidNotify() {
    const { company_name, job_role, desc, started, ended } = this.state.activeItem
    const { notify } = this.props.actions
    const baseError = {
      title: `Could not save previous experience form`,
    }
    if (hasEmpty(company_name, job_role, desc, started)) {
      notify('error', { ...baseError, message: 'All fields must be filled out.' })
      return false
    }
    // Checks if the dates are OK.
    if ((!hasEmpty(started) && hasInvalidMMYYYY(started)) || (!hasEmpty(ended) && hasInvalidMMYYYY(ended))) {
      notify('error', { ...baseError, message: 'Check that the start/end dates are in MM/YYYY format.' })
      return false
    }
    // Check if 'started' comes after 'ended'.
    if (!hasEmpty(started, ended) && !startedComesFirst(started, ended)) {
      notify('error', { ...baseError, message: 'The given start date comes after the end date.' })
      return false
    }
    return true
  }

  /** Returns whether this item is deletable (true only if it's already been saved once). */
  get isDeletable() {
    return get(this.state.activeItem, 'id', 0) > 0
  }

  /** As above, but displays a notification if not deletable. */
  get isDeletableNotify() {
    const { notify } = this.props.actions
    if (!this.isDeletable) {
      notify('error', {
        title: `Could not delete previous experience item`,
        message: 'This item has not been saved yet.',
      })
      return false
    }
    return true
  }

  handleFlexerProfileClick = (e, triggerEvent) => {
    const { workerID, actions, userId } = this.props
    const employerId = getDemoAccount()

    e.preventDefault()

    triggerEvent(trackingEvents.PREVIOUS_EXPERIENCE.PROFILE.CLICKED, { workerID })

    const userData = { id: userId }
    const tokenData = {
      id: employerId,
      approved_at: true,
      workerID,
    }
    getTokenOpenLogin({ actions, data: tokenData, userData, employerId })
  }

  handleCopy = triggerEvent => {
    const { workerID, actions } = this.props
    const employerId = getDemoAccount()

    triggerEvent(trackingEvents.PREVIOUS_EXPERIENCE.COPY.CLICKED)

    generateWorkerPortalUrl({ actions, employerId, workerID }).then(url => {
      if (url) {
        navigator.clipboard.writeText(url)
      }
    })
  }

  render() {
    const { workerID, roles, isLoading, isSaving } = this.props
    const { activeItem } = this.state
    const employerId = getDemoAccount()

    return (
      <WorkerPrevExpWrapper
        isLoading={isLoading || isSaving}
        isValid={this.isValid}
        isDeletable={this.isDeletable}
        activeItem={activeItem}
        roles={roles}
        updateActiveItem={this.updateActiveItem}
        clearActiveItem={this.clearActiveItem}
        deleteActiveItem={this.deleteActiveItem}
        saveActiveItem={this.saveActiveItem}
      >
        <WorkerPrevExpList
          workerID={workerID}
          selectCallback={this.selectItem}
          selectSingleRow
          selectFullRow
        />
        {employerId && (
          <TriggerEventComponent>
            {({ triggerEvent }) => (
              <div className="portal-actions">
                <Link
                  onClick={e => this.handleFlexerProfileClick(e, triggerEvent)}
                  to="#"
                  target="_blank"
                  style={{ color: 'black' }}
                >
                  <div className="icon-wrapper">
                    <LinkExternalIcon iconSize={12} />
                    Flexer profile
                  </div>
                </Link>
                <Button onClick={() => this.handleCopy(triggerEvent)}>
                  <div className="icon-wrapper">
                    <ClippyIcon iconSize={12} />
                    Copy
                  </div>
                </Button>
              </div>
            )}
          </TriggerEventComponent>
        )}
      </WorkerPrevExpWrapper>
    )
  }
}

export default connect(
  state => ({
    isLoading: state.prevExp.isLoadingData,
    isSaving: state.prevExp.isSavingData,
    roles: selectRolesOptions(state),
    userId: state?.auth?.userData?.id,
    demoAccount: getDemoAccount,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        ...notificationActions,
        ...prevExpActions,
        ...modalActions,
        ...employerActions,
        ...employerTokenActions,
      },
      dispatch,
    ),
  }),
)(WorkerPrevExp)
