// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './RecordList.css'

const RecordList = props => <div className={classnames('list', props.className)}>{props.children}</div>
RecordList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
RecordList.defaultProps = {
  children: null,
  className: '',
}

export default RecordList
