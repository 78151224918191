// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { Login } from './core/views2/Login'
import { Changelog } from './core/views2/Changelog'

import {
  WatchlistAlerts,
  WatchlistCompliance,
  WatchlistConversationsContainer,
  WatchlistConversationsList,
  WatchlistConversationsView,
  WatchListCsat,
  WatchlistDocuments,
  WatchlistFeedback,
  WatchlistStrikes,
} from './core/views2/Watchlist'
import {
  EntityTransferredWorkersList,
  EntityWorkersList,
  EntityWorkersNetworksList,
  EntityWorkersNetworksNew,
  EntityWorkersNetworksView,
  EntityWorkersOnboarding,
  EntityWorkersStrikesList,
  EntityWorkersStrikesNew,
  EntityWorkersStrikesView,
  EntityWorkersTransferDetailView,
  EntityWorkersView,
} from './core/views2/Entity/Workers'
import { EntityUsersList, EntityUsersView } from './core/views2/Entity/Users'
import {
  AdhocShiftApproval,
  AdminClientApplicationsList,
  AdminManagersList,
  AdminManagersNew,
  AdminManagersView,
  CommissionsList,
  ManagerCommissionsView,
  AdminTimeOffRequestList,
  AdminTimeOffRequestView,
} from './core/views2/Admin'
import {
  AppealsList,
  AppealsNew,
  AppealsView,
  ConductReviewsList,
  ConductReviewsView,
} from './core/views2/Conduct'
import {
  FinanceAutoOfferRate,
  FinanceInvoicing,
  FinanceOverpayments,
  FinancePayments,
  FinanceLeaveRequests,
} from './core/views2/Finance'
import {
  AgencyShiftHistory,
  AgencyShifts,
  AllShifts,
  FlexShifts,
  Shifts,
  ShiftsFulfilment,
} from './core/views2/Shifts'

import BizDev from './core/views/BizDev'
import Conversion from './core/views/bizdev/Conversion'
import Dashboard from './core/views/Dashboard'
import ErrorLog from './core/views/ErrorLog'
import Home from './core/views/Home'
import Listings from './core/views/Listings'
import Marketing from './core/views/Marketing'
import MarketingUsers from './core/views/marketing/MarketingUsers'
import ReferralCodes from './core/views/marketing/ReferralCodes'
import UserSettings from './core/views/UserSettings'
import {
  EmployerAccountsList,
  EmployerAccountsNew,
  EmployerAccountsView,
  EmployerAlertsList,
  EmployerBlocklistList,
  EmployerHistoryList,
  EmployerList,
  EmployerNetworkList,
  EmployerNetworkView,
  EmployerPreferencesView,
  EmployerRatingsList,
  EmployerSalesForceList,
  EmployerSalesForceView,
  EmployerVenueAreaNew,
  EmployerVenueAreaView,
  EmployerVenueList,
  EmployerVenueNew,
  EmployerVenueView,
  EmployerView,
  Listing,
  ListingRatings,
  ListingView,
  NewEmployerRateCardList,
  NewEmployerRateCardNew,
  NewEmployerRateCardView,
} from './core/views/entities'
import { DataRequest } from './core/views2/DataRequest/DataRequest'
import {
  Agencies,
  AgencyAccounts,
  AgencyAccountsView,
  AgencyCoverageImportForm,
  AgencyView,
} from './core/views2/Agencies'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Error404 from './core/views/errors/Error404'
import Imports from './core/views2/Imports'
import { RouteConfig } from 'syft-acp-util/router/utils'

export const routesConfig: RouteConfig[] = [
  { path: '/login', component: Login, metadata: { accountablePartyId: 'sts-wfm-platform' } },
  {
    path: '/data-request/:reporter/:id',
    component: DataRequest,
    metadata: { accountablePartyId: 'sts-wfm-saas' },
  },
  {
    authRequired: true,
    layout: Home,
    children: [
      {
        path: '/changelog',
        metadata: { accountablePartyId: 'sts-wfm-platform' },
        component: Changelog,
      },
      {
        metadata: { accountablePartyId: 'sts-wfm-platform' },
        path: '/error-log',
        component: ErrorLog,
      },
      {
        metadata: { accountablePartyId: 'sts-wfm-platform' },
        path: '/settings',
        component: UserSettings,
      },
      {
        metadata: { accountablePartyId: 'sts-wfm-platform' },
        path: '/dashboard',
        component: Dashboard,
      },
      {
        path: '/bizdev',
        layout: BizDev,
        children: [
          {
            path: '/conversion',
            component: Conversion,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            to: 'conversion',
          },
        ],
      },
      {
        path: '/marketing',
        layout: Marketing,
        children: [
          {
            path: '/users',
            component: MarketingUsers,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/codes',
            component: ReferralCodes,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            to: 'users',
          },
        ],
      },
      {
        path: '/shifts',
        layout: Shifts,
        children: [
          {
            path: '/all-shifts',
            component: AllShifts,
            metadata: { accountablePartyId: 'sts-fulfilment-support' },
          },
          {
            path: '/flex',
            component: FlexShifts,
            metadata: { accountablePartyId: 'sts-fulfilment-support' },
          },
          {
            path: '/agency',
            component: AgencyShifts,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/agency-shift-history',
            component: AgencyShiftHistory,
            metadata: { accountablePartyId: 'sts-fulfilment-support' },
          },
          {
            path: '/fulfilment',
            component: ShiftsFulfilment,
            metadata: { accountablePartyId: 'sts-fulfilment-support' },
          },
          {
            to: 'all-shifts',
          },
        ],
      },
      {
        path: '/agencies',
        children: [
          {
            path: '/list',
            component: Agencies,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },

          {
            path: '/coverage-imports',
            component: AgencyCoverageImportForm,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/view/:id',
            children: [
              {
                path: '/',
                exact: true,
                component: AgencyView,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              {
                path: '/accounts',
                children: [
                  {
                    path: '/list',
                    component: AgencyAccounts,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/view/:account_id',
                    component: AgencyAccountsView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/new',
                    component: AgencyAccountsView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    to: 'list',
                  },
                ],
              },
            ],
          },

          {
            to: 'list',
          },
        ],
      },

      {
        path: '/entity/employers',
        children: [
          {
            path: '/list',
            component: EmployerList,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },

          {
            path: '/view/:id',
            children: [
              {
                path: '/',
                exact: true,
                component: EmployerView,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              {
                path: '/venues',
                children: [
                  {
                    path: '/list',
                    component: EmployerVenueList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/view/:venueID',
                    children: [
                      {
                        path: '/',
                        exact: true,
                        component: EmployerVenueView,
                        metadata: { accountablePartyId: 'sts-wfm-saas' },
                      },
                      {
                        path: '/areas',
                        children: [
                          {
                            path: '/new',
                            component: EmployerVenueAreaNew,
                            metadata: { accountablePartyId: 'sts-wfm-saas' },
                          },
                          {
                            path: '/view/:areaID',
                            component: EmployerVenueAreaView,
                            metadata: { accountablePartyId: 'sts-wfm-saas' },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: '/new',
                    component: EmployerVenueNew,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    to: 'list',
                  },
                ],
              },
              {
                path: '/networks',
                children: [
                  {
                    path: '/list',
                    component: EmployerNetworkList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/view/:networkID',
                    component: EmployerNetworkView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },

              {
                path: '/accounts',
                children: [
                  {
                    path: '/new',
                    component: EmployerAccountsNew,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/list',
                    component: EmployerAccountsList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/view/:accountID',
                    component: EmployerAccountsView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    to: 'list',
                  },
                ],
              },

              {
                path: '/alerts',
                children: [
                  {
                    path: '/list',
                    component: EmployerAlertsList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },

              {
                path: '/ratings',
                children: [
                  {
                    path: '/list',
                    component: EmployerRatingsList,
                    metadata: { accountablePartyId: 'sts-pay' },
                  },
                  { to: 'list' },
                ],
              },

              {
                path: '/history',
                children: [
                  {
                    path: '/list',
                    component: EmployerHistoryList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },
              {
                path: '/blocklist',
                children: [
                  {
                    path: '/list',
                    component: EmployerBlocklistList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },
              {
                path: '/salesforce',
                children: [
                  {
                    path: '/list',
                    component: EmployerSalesForceList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/view/:salesforceID',
                    component: EmployerSalesForceView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },
              {
                path: '/ratecards-v2',
                children: [
                  {
                    path: '/list',
                    component: NewEmployerRateCardList,
                    metadata: { accountablePartyId: 'sts-pay' },
                  },
                  {
                    path: '/view/:rateCardID',
                    component: NewEmployerRateCardView,
                    metadata: { accountablePartyId: 'sts-pay' },
                  },
                  {
                    path: '/new',
                    component: NewEmployerRateCardNew,
                    metadata: { accountablePartyId: 'sts-pay' },
                  },
                  { to: 'list' },
                ],
              },
              {
                path: '/preferences',
                children: [
                  {
                    path: '/list',
                    component: EmployerPreferencesView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    to: 'list',
                  },
                ],
              },
            ],
          },

          {
            path: '/view/:employer_id',
            children: [
              {
                path: '/commissions',
                children: [
                  {
                    path: '/list',
                    component: CommissionsList,
                    metadata: { accountablePartyId: 'sts-time' },
                  },
                  {
                    path: '/view/:sales_win_association_id',
                    children: [
                      {
                        path: '/employer',
                        component: ManagerCommissionsView,
                        metadata: { accountablePartyId: 'sts-time' },
                      },
                      {
                        path: '/work_location/:work_location_id',
                        component: ManagerCommissionsView,
                        metadata: { accountablePartyId: 'sts-time' },
                      },
                    ],
                  },
                  {
                    to: 'list',
                  },
                ],
              },
            ],
          },

          {
            to: 'list',
          },
        ],
      },

      {
        path: '/listings',
        children: [
          {
            path: '/',
            exact: true,
            component: Listings,
            metadata: { accountablePartyId: 'sts-fulfilment-support' },
          },
          {
            path: '/view/:id',
            layout: Listing,
            children: [
              {
                path: '/',
                component: ListingView,
                exact: true,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
              {
                path: ['/job/:jobID', '/job/:jobID/shift/:shiftID'],
                exact: true,
                component: ListingView,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
            ],
          },
          {
            path: '/ratings/:id',
            layout: Listing,
            children: [
              {
                path: ['/', '/job/:jobID', '/job/:jobID/shift/:shiftID'],
                exact: true,
                component: ListingRatings,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
            ],
          },
          {
            path: '/imports',
            layout: Listing,
            children: [
              { path: '/', component: Imports, metadata: { accountablePartyId: 'sts-fulfilment-support' } },
            ],
          },
        ],
      },
      {
        path: '/finance',
        children: [
          {
            path: '/invoicing',
            component: FinanceInvoicing,
            metadata: { accountablePartyId: 'sts-pay' },
          },

          {
            path: '/payments',
            component: FinancePayments,
            metadata: { accountablePartyId: 'sts-pay' },
          },

          {
            path: '/overpayments',
            component: FinanceOverpayments,
            metadata: { accountablePartyId: 'sts-pay' },
          },

          {
            path: '/auto-offer-rate',
            component: FinanceAutoOfferRate,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },

          {
            path: '/leave-requests',
            component: FinanceLeaveRequests,
            metadata: { accountablePartyId: 'sts-pay' },
          },
          {
            to: 'invoicing',
          },
        ],
      },
      {
        path: '/admin',
        children: [
          {
            path: '/managers',
            children: [
              {
                path: '/list',
                component: AdminManagersList,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              {
                path: '/new',
                component: AdminManagersNew,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              {
                path: '/view/:managerID',
                component: AdminManagersView,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              { to: 'list' },
            ],
          },
          {
            path: '/client-applications',
            children: [
              {
                path: '/list',
                component: AdminClientApplicationsList,
                metadata: { accountablePartyId: 'sts-wfm-platform' },
              },
              { to: 'list' },
            ],
          },
          {
            path: '/lta',
            children: [
              {
                path: '/time-off-requests',
                children: [
                  {
                    path: '/',
                    exact: true,
                    component: AdminTimeOffRequestList,
                    metadata: { accountablePartyId: 'sts-fulfilment-support' },
                  },
                  {
                    path: '/view/:id',
                    component: AdminTimeOffRequestView,
                    metadata: { accountablePartyId: 'sts-fulfilment-support' },
                  },
                ],
              },
            ],
          },
          {
            path: '/adhoc-shift-approval/:shiftBookingId',
            component: AdhocShiftApproval,
            metadata: { accountablePartyId: 'sts-time' },
          },
          {
            path: '/appeals',
            children: [
              {
                from: '/list',
                to: '/conduct/appeals/list',
              },
              {
                from: '/new',
                to: '/conduct/appeals/new',
              },
              {
                from: '/view/:id',
                to: '/conduct/appeals/view/:id',
              },
              {
                to: 'list',
              },
            ],
          },
          {
            path: '/conduct-reviews',
            children: [
              {
                from: '/list',
                to: '/conduct/conduct-reviews/list',
              },
              {
                from: '/view/:id',
                to: '/conduct/conduct-reviews/view/:id',
              },
              {
                to: 'list',
              },
            ],
          },
          { to: 'managers/list' },
        ],
      },
      {
        path: '/conduct',
        children: [
          {
            path: '/appeals',
            children: [
              {
                path: '/list',
                component: AppealsList,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
              {
                path: '/new',
                component: AppealsNew,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
              {
                path: '/view/:id',
                component: AppealsView,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
              { to: 'list' },
            ],
          },
          {
            path: '/conduct-reviews',
            children: [
              {
                path: '/list',
                component: ConductReviewsList,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
              {
                path: '/view/:id',
                component: ConductReviewsView,
                metadata: { accountablePartyId: 'sts-fulfilment-support' },
              },
              { to: 'list' },
            ],
          },
          { to: 'conduct-reviews/list' },
        ],
      },
      {
        path: '/entity/users',
        children: [
          {
            path: '/list',
            component: EntityUsersList,
            metadata: { accountablePartyId: 'sts-wfm-platform' },
          },
          {
            path: '/view/:userID',
            component: EntityUsersView,
            metadata: { accountablePartyId: 'sts-wfm-platform' },
          },
          { to: 'list' },
        ],
      },
      {
        path: '/entity/workers',
        children: [
          {
            path: '/list',
            component: EntityWorkersList,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/transferred/list',
            component: EntityTransferredWorkersList,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/view/:id',
            children: [
              {
                path: '/',
                exact: true,
                component: EntityWorkersView,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              {
                path: '/onboarding',
                children: [
                  {
                    path: '/list',
                    component: EntityWorkersOnboarding,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },
              {
                from: '/interviews',
                to: 'interviews/list',
              },

              {
                path: '/transfer-details',
                children: [
                  {
                    path: '/list',
                    component: EntityWorkersTransferDetailView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },

              {
                from: '/compliance',
                to: 'compliance',
              },

              {
                path: '/strikes',
                children: [
                  {
                    path: '/new',
                    component: EntityWorkersStrikesNew,
                    metadata: { accountablePartyId: 'indeed-flex' },
                  },
                  {
                    path: '/list',
                    component: EntityWorkersStrikesList,
                    metadata: { accountablePartyId: 'indeed-flex' },
                  },
                  {
                    path: '/view/:strikeID',
                    component: EntityWorkersStrikesView,
                    metadata: { accountablePartyId: 'indeed-flex' },
                  },
                  { to: 'list' },
                ],
              },

              {
                path: '/networks',
                children: [
                  {
                    path: '/new',
                    component: EntityWorkersNetworksNew,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/list',
                    component: EntityWorkersNetworksList,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  {
                    path: '/view/:networkID',
                    component: EntityWorkersNetworksView,
                    metadata: { accountablePartyId: 'sts-wfm-saas' },
                  },
                  { to: 'list' },
                ],
              },
              {
                to: '',
              },
            ],
          },
          { to: 'list' },
        ],
      },
      {
        path: '/watchlist',
        children: [
          {
            path: '/strikes',
            component: WatchlistStrikes,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/alerts',
            component: WatchlistAlerts,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/conversations',
            layout: WatchlistConversationsContainer,
            children: [
              {
                path: '/list',
                component: WatchlistConversationsList,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              {
                path: '/view/:id',
                component: WatchlistConversationsView,
                metadata: { accountablePartyId: 'sts-wfm-saas' },
              },
              { to: 'list' },
            ],
          },

          {
            path: '/feedback',
            component: WatchlistFeedback,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },

          {
            path: '/compliance',
            component: WatchlistCompliance,
            metadata: { accountablePartyId: 'sts-flexer-expansion' },
          },
          {
            path: ['/workerCsat', '/employerCsat', '/agencyCsat'],
            component: WatchListCsat,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          {
            path: '/documents',
            component: WatchlistDocuments,
            metadata: { accountablePartyId: 'sts-wfm-saas' },
          },
          { to: 'strikes' },
        ],
      },
      { exact: true, from: '/', to: '/dashboard' },
      {
        path: '*',
        component: Error404,
        metadata: { accountablePartyId: 'sts-wfm-platform' },
      },
    ],
  },
]
