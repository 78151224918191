// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

export const orgTypes = [
  [
    'Hospitality',
    [
      { id: 'Restaurants', label: 'Restaurants' },
      { id: 'Fast Food', label: 'Fast Food' },
      { id: 'Stadiums', label: 'Stadiums' },
      { id: 'Event Catering', label: 'Event Catering' },
      { id: 'Cafes', label: 'Cafes' },
      { id: 'Hotels', label: 'Hotels' },
      { id: 'Festival', label: 'Festival' },
      { id: 'B&B / Boutique Hotels', label: 'B&B / Boutique Hotels' },
      { id: 'Social / Private Clubs', label: 'Social / Private Clubs' },
      { id: 'Resorts', label: 'Resorts' },
    ],
  ],
  [
    'Logistics',
    [
      { id: 'Manufacturing', label: 'Manufacturing' },
      { id: 'E-commerce', label: 'E-commerce' },
      { id: 'Third-party Logistics', label: 'Third-party Logistics' },
      { id: 'Food Production', label: 'Food Production' },
      { id: 'Automobile', label: 'Automobile' },
      { id: 'Supply Chain Services', label: 'Supply Chain Services' },
      { id: 'Transportation and Logistics', label: 'Transportation and Logistics' },
      { id: 'Distribution (warehouses)', label: 'Distribution (warehouses)' },
      { id: 'Fulfillment Centers', label: 'Fulfillment Centers' },
      { id: 'Grocery', label: 'Grocery' },
    ],
  ],
]
