export const trackingEvents = {
  PREVIOUS_EXPERIENCE: {
    PROFILE: {
      CLICKED: 'previous_experience.profile.clicked',
    },
    COPY: {
      CLICKED: 'previous_experience.copy.clicked',
    },
  },
}
