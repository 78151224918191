export const trackingEvents = {
  CLOSE_BUTTON: {
    CLICKED: 'retrospective_agency_hours_modal.close_button.clicked',
  },
  CANCEL_BUTTON: {
    CLICKED: 'retrospective_agency_hours_modal.cancel_button.clicked',
  },
  DOWNLOAD_BUTTON: {
    CLICKED: 'retrospective_agency_hours_modal.download_button.clicked',
  },
  DATE: {
    CHANGED: 'retrospective_agency_hours_modal.date.changed',
  },
}
