// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { TrackingProvider } from '@indeed/flex-tracking-context'

import PageTitle from 'syft-acp-core/components/PageTitle'
import EmployerDetail from 'syft-acp-core/entities/EmployerDetail/EmployerDetail'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

const EmployerView = ({ match: { params } }) => {
  return (
    <TrackingProvider
      value={{
        context: {
          employer_id: Number(params.id),
        },
      }}
    >
      <PageTitle title="Employer">
        <div>
          <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
          <EmployerDetailView id={Number(params.id)} />
        </div>
      </PageTitle>
    </TrackingProvider>
  )
}

const EmployerDetailView = ({ id }) => {
  const showGranularShiftEditSettings = useFlexFlagIsOn('job_postings__gse_settings')
  const showGSEPayEdits = useFlexFlagIsOn('job_postings__gse_pay_edits')
  const showGSERoleEdits = useFlexFlagIsOn('job_postings__gse_role_edits')
  const showGSETimeEdits = useFlexFlagIsOn('job_postings__gse_time_edits')
  const showBillableCheckbox = useFlexFlagIsOn('job_postings__admin_billable_checkbox')
  const showReasonsCheckbox = useFlexFlagIsOn('job_postings__admin_reasons_checkbox')
  return (
    <EmployerDetail
      id={id}
      showGranularShiftEditSettings={showGranularShiftEditSettings}
      showGSEPayEdits={showGSEPayEdits}
      showGSERoleEdits={showGSERoleEdits}
      showGSETimeEdits={showGSETimeEdits}
      showBillableCheckbox={showBillableCheckbox}
      showReasonsCheckbox={showReasonsCheckbox}
    />
  )
}

EmployerView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // Note: ID gets passed as a string directly from the URL.
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default EmployerView
