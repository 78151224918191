import { TokenResponse, GetTokenProps, TokenActions } from './types'
import { dataStructure } from './structure'

export const createUrl = ({
  res,
  userID,
  actions,
  id,
  listingId,
  blockedWorkers,
  shiftID,
  workerID,
}: {
  actions: TokenActions
  res: { payload: { url: string } }
  userID: number
  id: number
  listingId?: number
  blockedWorkers?: boolean
  shiftID?: number
  workerID?: number
}) => {
  const url = res?.payload?.url
  if (!url) return null
  if (listingId) {
    const { domain, token } = parsePortalUrl(url)
    const listingURL = `https:${domain}day-schedule/${listingId}/shifts/${
      shiftID || 'first'
    }?access_token=${token}&impersonatorId=${userID}`
    actions.saveEmployerToken({ id, url: listingURL })
    return window.open(listingURL)
  }
  if (workerID) {
    const { domain, token } = parsePortalUrl(url)
    const workerURL = `https:${domain}worker/${workerID}?access_token=${token}&impersonatorId=${userID}`
    actions.saveEmployerToken({ id, url: workerURL })
    return window.open(workerURL)
  }
  if (blockedWorkers) {
    const { domain, token } = parsePortalUrl(url)
    const listingURL = `https:${domain}settings/blocked-workers?access_token=${token}&impersonatorId=${userID}`
    actions.saveEmployerToken({ id, url: listingURL })
    return window.open(listingURL)
  }
  // E.g. 'https://portal.syftapp.com/listings/95453?access_token=xxxxx'
  const payloadURL = `${url}&impersonatorId=${userID}`
  actions.saveEmployerToken({ id, url: payloadURL })
  return window.open(payloadURL)
}

export const generateWorkerPortalUrl = ({
  actions,
  employerId,
  workerID,
}: {
  actions: TokenActions
  employerId: number
  workerID?: number
}): Promise<string | null> => {
  actions.setEmployerLoading({ id: employerId })
  return new Promise<TokenResponse>(resolve => actions.loginAsEmployer(employerId, resolve)).then(res => {
    const url = res?.payload?.url
    if (!url) return null

    const domainMatches = url.match(/\/\/(.+?)\.(.+?)\.(.+?)\//)
    const domain = domainMatches ? domainMatches[0] : ''
    const workerURL = `https:${domain}worker/${workerID}`
    return workerURL
  })
}

export const refetchUrlForNewListings = (token: { url: string }, listingId?: number) =>
  (token?.url && listingId && !token?.url?.includes(String(listingId))) ||
  (!listingId && token?.url?.includes('listings'))

export const getTokenOpenLogin = ({
  data,
  userData,
  token,
  actions,
  employerId,
  blockedWorkers,
}: GetTokenProps) => {
  const isApproved = !!data?.approved_at || false
  const listingId = data?.listingId
  const userID = Number(userData?.id)
  const shiftID = data?.shiftID
  const workerID = data?.workerID

  // Don't request a token if this employer isn't approved.
  if (!isApproved) {
    return null
  }

  const id = data?.id || employerId
  // We don't load if we don't have an ID or if we're already loading.
  if (id && !token?.loading) {
    actions.setEmployerLoading({ id })
    new Promise<TokenResponse>(resolve => actions.loginAsEmployer(id, resolve)).then(res => {
      return createUrl({ res, userID, actions, id, listingId, blockedWorkers, shiftID, workerID })
    })
  }
  return null
}

export const getEmployerGSEShift = (showGSETimeEdits: boolean) => {
  const granularShiftEditsList = [dataStructure.granularShiftEdits[0]]
  if (showGSETimeEdits) granularShiftEditsList.push(dataStructure.granularShiftEdits[1])
  granularShiftEditsList.push(dataStructure.granularShiftEdits[2])
  return granularShiftEditsList
}

const parsePortalUrl = (url: string): { domain: string; token: string } => {
  const tokenMatches = url.match(/access_token=([^"]+)/)
  const domainMatches = url.match(/\/\/(.+?)\.(.+?)\.(.+?)\//)
  const token = tokenMatches ? tokenMatches[1] : '' // e.g. '884832203'
  const domain = domainMatches ? domainMatches[0] : '' // e.g. '//portal.indeedflex.co.uk/'
  return { domain, token }
}
