// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import * as actions from 'syft-acp-core/actions/previous-experience'
import * as tableType from 'syft-acp-core/components/EntityList/'
import entityListFactory from 'syft-acp-util/entityList'
import RecordList from 'syft-acp-core/components/RecordSection/RecordList'
import Reference from './Reference'

import { referenceStatuses } from './statuses'

// Quick wrapper that removes everything except the list contents.
const minimalWrapper = ({ entityList }) => <RecordList>{entityList}</RecordList>

const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  { type: tableType.TXT, val: 'company_name', header: 'Company name' },
  { type: tableType.TXT, val: 'job_role', header: 'Job role' },
  { type: tableType.TXT, val: 'desc', header: 'Job description', classes: ['main'] },
  {
    type: tableType.TXT,
    copyValFn: (data = {}) => {
      // Format the name, email and telephone number in a single string.
      const name = data.reference_name || '(no name given)'
      const email = data.reference_email ? `<${data.reference_email}>` : name ? '(no email given)' : ''
      const tel = data.reference_phone_number
        ? `(tel: ${data.reference_phone_number})`
        : name && email
        ? '(no tel nr. given)'
        : ''

      const refPerson = [name, email, tel].join(' ').trim()
      return refPerson
    },
    copyable: true,
    val: data => (
      <Reference name={data.reference_name} email={data.reference_email} tel={data.reference_phone_number} />
    ),
    header: 'Reference',
  },
  { type: tableType.TAGS, val: 'reference_status', header: 'Status', typesMap: referenceStatuses, classes: ['nowrap'] },
  { type: tableType.TXT, val: 'started', header: 'Started', classes: ['nowrap'] },
  { type: tableType.TXT, val: 'ended', header: 'Ended', classes: ['nowrap'] },
]

const filterFormat = <div />

const actionFormat = []

export default entityListFactory(
  minimalWrapper,
  tableFormat,
  filterFormat,
  actionFormat,
  'prevExp',
  {
    fetchEntities: (params, { workerID }) => actions.listPrevExp(workerID),
  },
  {
    recordList: true,
    disableAutoScroll: true,
  }
)
