// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { TrackingTrigger, useTrackingTrigger } from '@indeed/flex-tracking-context'
import { Flex } from '@indeed/ifl-components'
import { flowRight } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { ConnectedProps, DefaultRootState, connect } from 'react-redux'
import { AnyAction, Dispatch, bindActionCreators } from 'redux'

import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import { CountryCode, SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import * as employerVenueActions from 'syft-acp-core/store/employer-venues/actions'
import * as employerRateCardActions from 'syft-acp-core/store/employerRateCards/actions'
import { EmployerRateCardEntity } from 'syft-acp-core/store/employerRateCards/types'
import { fetchEmployerShiftRateTypes } from 'syft-acp-core/store/employerShiftRateTypes/actions'
import {
  selectEmployerShiftRateTypesOptions,
  selectEmployerShiftRateTypesOptionsWithDisabled,
} from 'syft-acp-core/store/employerShiftRateTypes/selectors'
import { selectEmployerVenueOptions } from 'syft-acp-core/store/employerVenuesFiltered/selectors'
import * as employerActions from 'syft-acp-core/store/employers/actions'
import { selectRolesOptions } from 'syft-acp-core/store/roles/selectors'
import { listSkills } from 'syft-acp-core/store/skills/actions'
import { selectSkillsOptions } from 'syft-acp-core/store/skills/selectors'
import { selectVenueCategoryOptionsByEmployerId } from 'syft-acp-core/store/venueCategories/selectors'
import { entityDetailHOC } from 'syft-acp-util/entityDetail'

import { Button, ButtonLink } from 'syft-acp-atoms/Button'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { Modal } from 'syft-acp-core/components/Modal'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import { GetEntityDetailHocProps } from 'types/utils'

import { trackingEvents } from './EmployerRateCardForm.tracking'
import RateCardReplacedModal from './RateCardReplacedModal'
import TimeDependentRatesForm from './TimeDependentRates'
import { TimeDependentRatesTrackingProvider } from './TimeDependentRatesTrackingProvider'
import { getDataStructure } from './constants'

import './EmployerRateCardForm.styles.css'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

type Props = {
  id?: number
  employerId: number
}

type PropsWithConnectors = Props &
  ConnectedProps<typeof storeConnector> &
  GetEntityDetailHocProps<typeof entityDetailConnector>

const validateForm = (data: EmployerRateCardEntity, countryCode: CountryCode, awrEnabled: boolean) => {
  const errors: string[] = []

  if (!data.role_id) {
    errors.push('Role is required')
  }

  if (!data.default_payable_rate) {
    errors.push('Default rate is required')
  }

  if (!data.min_payable_rate) {
    errors.push('Minimum rate is required')
  }

  if (!data.max_payable_rate) {
    errors.push('Maximum rate is required')
  }

  if (awrEnabled && countryCode === SUPPORTED_COUNTRY_CODES.GB && !data.awr_parity_rate) {
    errors.push('AWR parity rate is required')
  }

  if (countryCode === SUPPORTED_COUNTRY_CODES.GB && !data.margins_percent && !data.invoice_rate) {
    errors.push('Margin percentage or invoice rate is required')
  }

  if (countryCode !== SUPPORTED_COUNTRY_CODES.GB && !data.fee_percent && !data.invoice_rate) {
    errors.push('Fee percentage or invoice rate is required')
  }

  if (!data.effective_date) {
    errors.push('Effective date is required')
  }

  return errors
}

export const EmployerRateCardForm = ({
  data,
  employerId,
  id,
  venueCategories,
  employerVenuesFiltered,
  roles,
  skills,
  actions,
  countryCode,
  rateCardDisabled,
  isSavingData,
  shiftRateTypes,
  actionUpdate,
  isLoading,
}: PropsWithConnectors) => {
  const triggerEvent = useTrackingTrigger()
  const [confirmModal, setConfirmModal] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const awrEnabled = useFlexFlagIsOn('acp_agency_worker_rates')

  useEffect(() => {
    // we can get skills from roles without request another call for skills.
    // But after revisit the `RoleEntity` as it contains skills as number[]
    // not `SkillEntiy[]`.
    actions.listSkills()

    if (id) {
      actions.fetchEmployerRateCard(id)
    }
  }, [actions, id])

  useEffect(() => {
    actions.fetchEmployerShiftRateTypes(employerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (confirmModal) {
      triggerEvent(trackingEvents[`${rateCardDisabled ? 'ENABLE' : 'DISABLE'}_MODAL`].VIEWED, {
        employer_id: employerId,
        rate_card_id: id,
      })
    }
  }, [triggerEvent, confirmModal, rateCardDisabled, employerId, id])

  const dataStructure = useMemo(
    () =>
      getDataStructure({
        venueCategories,
        employerVenues: employerVenuesFiltered,
        roles,
        skills,
        shiftRateTypes,
        countryCode,
        awrEnabled,
      }),
    [venueCategories, employerVenuesFiltered, roles, skills, shiftRateTypes, countryCode, awrEnabled],
  )

  const handleClose = () => {
    setConfirmModal(false)
    triggerEvent(trackingEvents[`${rateCardDisabled ? 'ENABLE' : 'DISABLE'}_MODAL_CANCEL`].CLICKED, {
      employer_id: employerId,
      rate_card_id: id,
    })
  }

  const handleEnable = () => {
    setConfirmModal(true)
    triggerEvent(trackingEvents.ENABLE.CLICKED, {
      employer_id: employerId,
      rate_card_id: id,
    })
  }

  const handleDisable = () => {
    setConfirmModal(true)
    triggerEvent(trackingEvents.DISABLE.CLICKED, {
      employer_id: employerId,
      rate_card_id: id,
    })
  }

  const handleConfirm = () => {
    if (rateCardDisabled) {
      actions.enableEmployerRateCard(id)
      setConfirmModal(false)
      triggerEvent(trackingEvents.ENABLE_MODAL_OK.CLICKED, {
        employer_id: employerId,
        rate_card_id: id,
      })
      return
    }
    actions.disableEmployerRateCard(id)
    setConfirmModal(false)
    triggerEvent(trackingEvents.DISABLE_MODAL_OK.CLICKED, {
      employer_id: employerId,
      rate_card_id: id,
    })
  }

  const handleSaveRateCard = () => {
    const errors = validateForm(data, countryCode, awrEnabled)
    setFormErrors(errors)
    triggerEvent(trackingEvents.SAVE.CLICKED, {
      employer_id: employerId,
      rate_card_id: id,
    })

    if (!errors.length) {
      triggerEvent(trackingEvents[`${id ? 'EDIT' : 'NEW'}`].SUBMITTED, {
        employer_id: employerId,
        rate_card_id: id,
      })
      actions.saveEmployerRateCard(employerId, data.id, data)
    }
  }

  return (
    <SiteComponent className="employer-rate-card-form">
      <>
        <Grid>
          <Row>
            <Col md={12}>
              <RecordControls>
                <div>
                  <ButtonLink to={`/entity/employers/view/${employerId}/ratecards-v2/list`}>
                    Back to rate cards
                  </ButtonLink>
                </div>
                {!isLoading ? (
                  <>
                    <div className="employer-rate-card-form__actions right">
                      {id && rateCardDisabled && (
                        <Button kind="primary" onClick={handleEnable}>
                          Enable
                        </Button>
                      )}
                      {id && !rateCardDisabled && (
                        <Button kind="danger" onClick={handleDisable}>
                          Disable
                        </Button>
                      )}
                      <Button kind="success" onClick={handleSaveRateCard}>
                        Save
                      </Button>
                    </div>
                  </>
                ) : null}
              </RecordControls>
              <NotifyBar severity="error" conditional visible={formErrors.length > 0} noMargin>
                <div className="employer-rate-card-form__title">Errors</div>
                <ul className="employer-rate-card-form__errors">
                  {formErrors.map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </NotifyBar>
              {isLoading ? (
                <RecordSection>
                  <Flex sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                    <LoadingSpinner isLoading={true} />
                  </Flex>
                </RecordSection>
              ) : (
                <RecordSection
                  data={
                    data.id
                      ? {
                          ...data,
                          effective_date: data.effective_date === '1900-01-01' ? '' : data.effective_date,
                        }
                      : {
                          ...data,
                          employer_id: employerId,
                        }
                  }
                  structure={dataStructure}
                  update={actionUpdate}
                  title={data.id ? `Rate card: ${data.id}` : 'New rate card'}
                />
              )}
            </Col>
          </Row>
          {!isLoading && countryCode === SUPPORTED_COUNTRY_CODES.GB ? (
            <Row>
              <Col md={12}>
                {id ? (
                  <TimeDependentRatesTrackingProvider employerId={employerId} rateCardId={id}>
                    <TimeDependentRatesForm
                      title="Time Dependent Rates"
                      rateCardId={id}
                      hideMarginField={!!data.invoice_rate}
                      hideInvoiceField={!!data.margins_percent}
                    />
                  </TimeDependentRatesTrackingProvider>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </Grid>

        <Modal
          header={`${rateCardDisabled ? 'Enable' : 'Disable'} rate card`}
          isShown={confirmModal}
          isLoading={isSavingData}
          confirmationText="Confirm"
          cancelText="Cancel"
          onClose={handleClose}
          onConfirm={handleConfirm}
        >
          {`Are you sure you want to ${rateCardDisabled ? 'enable' : 'disable'} this rate card?`}
        </Modal>

        <RateCardReplacedModal />
        <TrackingTrigger
          onLoad={{
            event: id ? trackingEvents.EDIT.VIEWED : trackingEvents.NEW.VIEWED,
            payload: {
              employer_id: employerId,
              rate_card_id: id,
            },
          }}
        />
      </>
    </SiteComponent>
  )
}

const mapStateToProps = (state: DefaultRootState, ownProps: any) => ({
  skills: selectSkillsOptions(state),
  roles: selectRolesOptions(state),
  employerVenuesFiltered: selectEmployerVenueOptions(state),
  venueCategories: selectVenueCategoryOptionsByEmployerId(state, ownProps.employerId),
  shiftRateTypes: ownProps.id
    ? selectEmployerShiftRateTypesOptionsWithDisabled(state, ownProps.employerId, ownProps.id)
    : selectEmployerShiftRateTypesOptions(state, ownProps.employerId),
  countryCode: state.app.countryCode,
  rateCardDisabled: !!state.newEmployerRateCards.entityMap[ownProps.id]?.disabled_at,
  isSavingData: state.newEmployerRateCards.isSavingData,
  isLoading:
    state.industries.isLoadingData ||
    state.roles.isLoadingData ||
    state.skills.isLoadingData ||
    state.employerShiftRateTypes.isLoadingData ||
    state.venueCategories.isLoadingData ||
    state.employerVenuesFiltered.isLoadingData ||
    state.newEmployerRateCards.isLoadingData,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      ...employerVenueActions,
      ...employerActions,
      ...employerRateCardActions,
      listSkills,
      fetchEmployerShiftRateTypes,
    },
    dispatch,
  ),
})

export const storeConnector = connect(mapStateToProps, mapDispatchToProps)
export const entityDetailConnector = entityDetailHOC('newEmployerRateCards')
export default flowRight(storeConnector, entityDetailConnector)(EmployerRateCardForm)
