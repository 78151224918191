import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

const ConductSubNav = () => (
  <SubNavigation>
    <SubLink to="/conduct/appeals">Appeals</SubLink>
    <SubLink to="/conduct/conduct-reviews">Conduct reviews</SubLink>
  </SubNavigation>
)

export default ConductSubNav
