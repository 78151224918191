// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import accessRequestDataSagas from './access-request-data'
import agenciesSagas from './agencies'
import agenciesList from './agencies-list'
import agencyAccounts from './agency-accounts'
import agencyBookings from './agency-bookings'
import agencyPlatform from './agency-platform'
import agencyProfile from './agency-profile'
import appSagas from './app'
import authSagas from './auth'
import bankHolidayRegions from './bankHolidayRegions'
import bookingsSagas from './bookings'
import citiesSagas from './cities'
import clientApplicationsSagas from './client-applications'
import commissionsSagas from './commissions'
import conversationsSagas from './conversations'
import countriesSagas from './countries'
import csatSagas from './csat'
import deletedRTWDocuments from './deleted-rtw-documents'
import desktopNotificationsSagas from './desktop-notifications'
import downloadSagas from './downloads'
import employerAccountsSagas from './employer-accounts'
import employerBlocklistsSagas from './employer-blocklists'
import employerHistorySagas from './employer-history'
import employerNetworksSagas from './employer-networks'
import employerRateCardSagas from './employer-ratecards'
import employerRatingsSagas from './employer-ratings'
import employerSalesForceSagas from './employer-salesforce'
import employerTemplatesSagas from './employer-templates'
import employerVenueNamesSagas from './employer-venue-names'
import employerVenuesFilteredSagas from './employer-venues-filtered'
import healthQuestionnairesSagas from './health-questionnaires'
import imagesSagas from './images'
import importLogEventsSagas from './import-log-events'
import industriesSagas from './industries'
import managersSagas from './managers'
import noShowReasonsSagas from './no-show-reasons'
import oauthDataSync from './sync-oauth-auth'
import overpaymentsSagas from './overpayments'
import paymentsSagas from './payments'
import prevExpSagas from './prev-exp'
import ratingsSagas from './ratings'
import statsSagas from './stats'
import strikesSagas from './strikes'
import timeDependentRates from './timeDependentRates'
import timekeepingSagas from './timekeeping'
import userActivity from './user-activity'
import userSettingsSagas from './user-settings'
import workerCompletedShiftsSagas from './worker-completed-shifts'
import workerDocumentTypesSagas from './worker-document-types'
import workerDocumentsSagas from './worker-documents'
import workerFeedbackSagas from './worker-feedback'
import workerLeaveRequests from './worker-leave-requests'
import workerNetworksSagas from './worker-networks'
import workerTransferSagas from './worker-transfers'
import workerUpcomingJobsSagas from './worker-upcoming-jobs'

// ducks
// TODO move this index.ts file to /store/sagas.js when all ducks are ready
import adhocShiftApprovalSagas from '../store/adhocShiftBookings/saga'
import agencyShiftHistorySagas from '../store/agency-shift-history/saga'
import agencyShifts from '../store/agency-shifts/saga'
import agencyBulkUpload from '../store/agency-bulk-upload/saga'
import agencyUnfilledAllocationsSagas from '../store/agency-unfilled-allocations/saga'
import agencyVenuesSagas from '../store/agency-venues/saga'
import agencyLogin from '../store/agencyLogin/saga'
import alertsSagas from '../store/alerts/saga'
import autoOfferRates from '../store/autoOfferRates/saga'
import checkboxesSagas from '../store/checkboxes/saga'
import commissionsSalesSagas from '../store/commissionsSales/saga'
import compliancePoliciesSagas from '../store/compliancePolicies/saga'
import csatAgenciesSagas from '../store/csatAgencies/saga'
import csatEmployersSagas from '../store/csatEmployers/saga'
import csatWorkersSagas from '../store/csatWorkers/saga'
import deliveryTeamsSagas from '../store/delivery-teams/saga'
import employerVenuesSagas from '../store/employer-venues/saga'
import employerPreferencesSagas from '../store/employerPreferences/saga'
import employerSagas from '../store/employers/saga'
import employerShiftRateTypesSagas from '../store/employerShiftRateTypes/saga'
import newEmployerRateCardSagas from '../store/employerRateCards/saga'
import expiringDocumentsSagas from '../store/expiringDocuments/saga'
import externalPlatformsSagas from '../store/external-platforms/saga'
import externalEmployersSagas from '../store/externalEmployers/saga'
import externalWorkLocationsSagas from '../store/externalWorkLocations/saga'
import filtersSagas from '../store/filters/saga'
import fulfilmentShiftsSagas from '../store/fulfilmentShifts/saga'
import importSagas from '../store/import-file/saga'
import jobsSagas from '../store/jobs/saga'
import listingShiftsSagas from '../store/listing-shifts/saga'
import listingsSagas from '../store/listings/saga'
import managerCommissions from '../store/managerCommissions/saga'
import matchingExplainability from '../store/matching-explainability/saga'
import rolesSagas from '../store/roles/saga'
import shiftBookingsSagas from '../store/shift-bookings/saga'
import shiftsSagas from '../store/shifts/saga'
import shiftEvents from '../store/shiftEvents/saga'
import skillsSagas from '../store/skills/saga'
import usersSagas from '../store/users/saga'
import venueCategories from '../store/venueCategories/saga'
import watchlistCompliance from '../store/watchlistCompliance/saga'
import workerHolidayPaySagas from '../store/worker-holiday-pay/saga'
import workerMissingPaySagas from '../store/worker-missing-pay/saga'
import workerReferralBonusesSagas from '../store/workerReferralBonuses/saga'
import workerDuplicateAccountsCountSagas from '../store/worker-duplicate-accounts-count/saga'
import workerExpensesSagas from '../store/workerExpenses/saga'
import workerReferralClaimsSagas from '../store/workerReferralClaims/saga'
import workerMarketingPreferencesSagas from '../store/workerMarketingPreferences/saga'
import workersSagas from '../store/workers/saga'
import workerShiftsSagas from '../store/workerShifts/saga'
import workerStrikesSagas from '../store/workerStrikes/saga'
import workerTierSagas from '../store/workerTier/saga'
import conductReviewsSagas from '../store/conductReviews/saga'
import appealsSagas from '../store/appeals/saga'
import timeOffRequestsSagas from '../store/timeOffRequests/saga'

/**
 * Root saga that yields all available sagas.
 */
export default function* root() {
  yield* adhocShiftApprovalSagas()
  yield* agenciesList()
  yield* agenciesSagas()
  yield* agencyAccounts()
  yield* agencyBookings()
  yield* agencyLogin()
  yield* agencyPlatform()
  yield* agencyProfile()
  yield* agencyShiftHistorySagas()
  yield* agencyShifts()
  yield* agencyBulkUpload()
  yield* agencyUnfilledAllocationsSagas()
  yield* agencyVenuesSagas()
  yield* alertsSagas()
  yield* appSagas()
  yield* authSagas()
  yield* autoOfferRates()
  yield* bankHolidayRegions()
  yield* bookingsSagas()
  yield* checkboxesSagas()
  yield* citiesSagas()
  yield* clientApplicationsSagas()
  yield* commissionsSagas()
  yield* commissionsSalesSagas()
  yield* conversationsSagas()
  yield* countriesSagas()
  yield* csatSagas()
  yield* csatWorkersSagas()
  yield* csatEmployersSagas()
  yield* csatAgenciesSagas()
  yield* desktopNotificationsSagas()
  yield* deliveryTeamsSagas()
  yield* downloadSagas()
  yield* employerAccountsSagas()
  yield* employerBlocklistsSagas()
  yield* employerHistorySagas()
  yield* employerNetworksSagas()
  yield* employerRateCardSagas()
  yield* newEmployerRateCardSagas()
  yield* employerRatingsSagas()
  yield* employerSagas()
  yield* employerPreferencesSagas()
  yield* employerSalesForceSagas()
  yield* employerShiftRateTypesSagas()
  yield* employerVenuesSagas()
  yield* employerVenuesFilteredSagas()
  yield* employerVenueNamesSagas()
  yield* employerTemplatesSagas()
  yield* expiringDocumentsSagas()
  yield* externalPlatformsSagas()
  yield* externalEmployersSagas()
  yield* externalWorkLocationsSagas()
  yield* filtersSagas()
  yield* fulfilmentShiftsSagas()
  yield* healthQuestionnairesSagas()
  yield* imagesSagas()
  yield* importSagas()
  yield* importLogEventsSagas()
  yield* industriesSagas()
  yield* jobsSagas()
  yield* listingShiftsSagas()
  yield* listingsSagas()
  yield* managerCommissions()
  yield* matchingExplainability()
  yield* managersSagas()
  yield* noShowReasonsSagas()
  yield* oauthDataSync()
  yield* overpaymentsSagas()
  yield* paymentsSagas()
  yield* prevExpSagas()
  yield* ratingsSagas()
  yield* rolesSagas()
  yield* shiftBookingsSagas()
  yield* shiftsSagas()
  yield* shiftEvents()
  yield* skillsSagas()
  yield* statsSagas()
  yield* strikesSagas()
  yield* timeDependentRates()
  yield* timekeepingSagas()
  yield* userSettingsSagas()
  yield* usersSagas()
  yield* venueCategories()
  yield* workerCompletedShiftsSagas()
  yield* workerHolidayPaySagas()
  yield* workerMissingPaySagas()
  yield* workerDocumentsSagas()
  yield* workerDocumentTypesSagas()
  yield* workerDuplicateAccountsCountSagas()
  yield* workerFeedbackSagas()
  yield* workerNetworksSagas()
  yield* workerReferralClaimsSagas()
  yield* workerMarketingPreferencesSagas()
  yield* workerExpensesSagas()
  yield* workersSagas()
  yield* workerLeaveRequests()
  yield* workerReferralBonusesSagas()
  yield* workerShiftsSagas()
  yield* workerStrikesSagas()
  yield* workerTierSagas()
  yield* workerUpcomingJobsSagas()
  yield* workerTransferSagas()
  yield* accessRequestDataSagas()
  yield* userActivity()
  yield* deletedRTWDocuments()
  yield* compliancePoliciesSagas()
  yield* watchlistCompliance()
  yield* conductReviewsSagas()
  yield* appealsSagas()
  yield* timeOffRequestsSagas()
}
