import React from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { history } from 'syft-acp-core/history'

import { ButtonGroup, Button } from 'syft-acp-atoms/Button'
import { getWorker$ } from 'syft-acp-core/store/workers/selectors'
import WorkerHeader from 'syft-acp-core/entities/WorkerDetail/WorkerHeader'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import NewStrikeButton from 'syft-acp-core/components/Buttons/NewStrikeButton'
import WorkerStrikesList from './WorkerStrikesList/WorkerStrikesList'

const backAction = () => {
  history.push(`/entity/workers`)
}

const WorkerStrikeDetailsWrapper = props => (
  <SiteComponent className={props.className}>
    <div>
      <Grid>
        <Row>
          <Col md={12}>
            <RecordControls>
              <ButtonGroup>
                <Button onClick={backAction}>Back to list</Button>
              </ButtonGroup>
              <ButtonGroup>
                <NewStrikeButton label="New Strike" workerID={props.workerID} />
              </ButtonGroup>
            </RecordControls>
            <WorkerHeader data={props.workerData} />
          </Col>
        </Row>
      </Grid>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <WorkerStrikesList workerID={Number(props.workerID)} />
          </Col>
        </Row>
      </Grid>
    </div>
  </SiteComponent>
)

export default connect((state, ownProps) => ({
  workerData: getWorker$(state.workers)(ownProps.workerID),
}))(WorkerStrikeDetailsWrapper)
