import { Features } from 'syft-acp-core/components/FeatureFlaggingProvider'

export type OneHostFlagConfig = {
  flag: keyof Features
  pathname?: string
}

export type OneHostRouteRedirect = {
  redirect: boolean
  pathname?: string
}

export type PathConfig = OneHostFlagConfig | OneHostRouteRedirect

type OneHostRouteConfig = {
  [path: string]: PathConfig
}

export const oneHostRoutes: OneHostRouteConfig = {
  '/agencies/role-compliance': {
    redirect: true,
  },
  '/conduct/conduct-reviews/list': {
    flag: 'onehost_acp_nav_conduct_toggle',
  },
  '/conduct/appeals/list': {
    flag: 'onehost_acp_nav_conduct_toggle',
  },
  '/finance/holiday-pay': {
    redirect: true,
  },
  '/entity/workers/view/:id/compliance': {
    redirect: true,
  },
  '/shifts/all-shifts': {
    flag: 'onehost_acp_all_shift',
  },
  '/entity/employers/view/:employerID/insurance-class-codes': {
    redirect: true,
  },
  '/finance/rate-cards/agency': {
    redirect: true,
  },
  '/finance/insurance-class-codes': {
    redirect: true,
  },
  '/finance/bank-holidays': {
    redirect: true,
  },
  '/entity/workers/view/:id/interviews/list': {
    redirect: true,
  },
  '/watchlist/compliance': {
    flag: 'onehost_acp_watchlist_compliance',
  },
  '/watchlist/documents': {
    redirect: true,
  },
}
