// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'
import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

export default entityReducer('agencyUnfilledAllocations', {
  [entityTypes.ENTITY_FETCH_BEGIN]: types.AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.AGENCY_UNFILLED_ALLOCATIONS_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: types.AGENCY_UNFILLED_ALLOCATIONS_FETCH_FAILED,
  [entityTypes.ENTITY_DELETE_BEGIN]: types.AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN,
  [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED,
  [entityTypes.ENTITY_DELETE_FAILED]: types.AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED,
})
