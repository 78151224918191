import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { useMemo } from 'react'
import { WorkerEntity } from 'syft-acp-core/store/types'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { dataStructure } from 'syft-acp-core/entities/WorkerDetail/structure'
import { selectWorkerDocuments$ } from 'syft-acp-core/reducers/worker-documents'

import { useSelector } from 'react-redux'

export const useRtwStructure = (workerID: number) => {
  const workerDocuments = useSelector(state => selectWorkerDocuments$(state)(workerID))

  return useMemo(() => {
    // Override fields to make them non editable
    const viewDataInComplianceButton = [
      'compliance_link',
      {
        header: 'Compliance data',
        getData: () => `View data in compliance service`,
        parameters: {
          to: (wData: WorkerEntity) => wData.compliance_link,
          external: true,
          target: '_blank',
        },
        editable: false,
        hide: (wData: WorkerEntity) => {
          return !wData.compliance_link
        },
        type: structureTypes.TYPE_LINK,
      },
    ]

    return [
      viewDataInComplianceButton,
      ...dataStructure.visa.map(structure => {
        const [label, config] = structure
        if (typeof config === 'string') {
          return structure
        }

        const newConfig = { ...config }

        if ('type' in newConfig) {
          // Show update date for file upload fields
          if (newConfig.type === structureTypes.TYPE_ENUM && 'hasUpload' in newConfig) {
            newConfig.type = structureTypes.TYPE_DATE
            ;(newConfig as typeof newConfig & { getData: () => JSX.Element }).getData = () => {
              if (newConfig.uploadType in workerDocuments) {
                return workerDocuments[newConfig.uploadType].updated_at
              }

              return null
            }
          }
        }

        return [label, newConfig]
      }),
    ]
  }, [workerDocuments])
}

export const useInterviewStructure = () => {
  const isInterviewCompliance = useFlexFlagIsOn('acp_onboarding_interview_into_compliance')
  if (isInterviewCompliance) return ['Compliance', dataStructure.compliance]
  return ['Interview', dataStructure.interview]
}
