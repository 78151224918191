import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { fetchAppealReasons } from 'syft-acp-core/store/appeals/actions'
import { fetchUserBanReasons } from 'syft-acp-core/store/users/actions'
import { fetchWorkerStrikeReasons } from 'syft-acp-core/store/workerStrikes/actions'
import { Reason } from 'syft-acp-core/store/types'

import { filterSelect } from '../FilterSelect'
import { FilterReasonsProps as Props, FilterReasonsOwnProps as OwnProps, TypeMap } from './FilterReasons.types'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'

const typeMap: TypeMap = {
  bans: {
    fetch: fetchUserBanReasons,
    storeKey: 'users',
    reasonKey: 'userBanReasons',
    queryKey: 'ban_reason_id',
  },
  appeals: {
    fetch: fetchAppealReasons,
    storeKey: 'appeals',
    reasonKey: 'appealReasons',
    queryKey: 'appeal_reason_id',
  },
  strikes: {
    fetch: fetchWorkerStrikeReasons,
    storeKey: 'workerStrikes',
    reasonKey: 'workerStrikeReasons',
    queryKey: 'strike',
  },
}

export const storeConnector = connect(
  (state, { value, queryKey: queryKeyOverride, type }: OwnProps) => {
    const { storeKey, reasonKey, queryKey: queryKeyDefault } = typeMap[type]
    const queryKey = queryKeyOverride || queryKeyDefault
    return {
      value: value || state.routing.locationBeforeTransitions.query[queryKey],
      reasons: (state[storeKey] as any)[reasonKey] as Reason[] | undefined,
      queryKey,
    }
  },
  (dispatch, { actions, type }: OwnProps) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        fetchReasons: typeMap[type].fetch,
      },
      dispatch
    )

    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  }
)

const mapReasons = (
  reasons: NonNullable<Props['reasons']>,
  extraOptions: NonNullable<Props['extraOptions']>,
  idKey: NonNullable<Props['idKey']>,
  banPrefix = false
) => [
  ...extraOptions,
  ...reasons.map(reason => ({
    id: reason[idKey],
    label: `${banPrefix ? 'Ban - ' : ''}${reason.display_name}`,
  })),
]

const FilterReasons = ({
  actions,
  queryKey,
  value,
  reasons,
  extraOptions,
  idKey = 'id',
  headerLabel,
  testID,
  onChange,
  disabled = false,
  allowAny = false,
  banPrefix = false,
}: Props) => {
  const onChangeFn = onChange || ((val: Props['value']) => actions.setFilter(queryKey, val))
  const header = headerLabel || (allowAny ? 'Any' : 'Select a reason')
  const FilterSelect = filterSelect(
    mapReasons(reasons || [], extraOptions || [], idKey, banPrefix),
    header,
    'id',
    false
  )

  useEffect(() => {
    actions.fetchReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!reasons) return <LoadingSpinner isLoading={true} />

  return (
    <FilterSelect
      name={queryKey}
      onChange={onChangeFn}
      value={value}
      allowAny={allowAny}
      disabled={disabled}
      testID={testID}
    />
  )
}

export default storeConnector(FilterReasons)
