import React from 'react'
import { connect } from 'react-redux'
import { datetimeToDate } from 'syft-acp-util/time'
import { enGB } from 'date-fns/locale'

import { Modal, ModalBody, ModalFooter } from '@indeed/ipl-modal'
import { Box, Button, Text } from '@indeed/ifl-components'
import { DatePicker } from '@indeed/ipl-date-picker'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { hideModal } from 'syft-acp-core/store/modals/actions'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'

import { RetrospectiveAgencyHoursModalProps as Props } from './RetrospectiveAgencyHoursModal.types'
import { trackingEvents } from './RetrospectiveAgencyHoursModal.tracking'

const fetchRetrospectiveAgencyHours = (endDate: Date) => {
  return downloadAsFileFromApi('/admin/retrospective_agency_shift_workers.csv', {
    invoicing_end_date: datetimeToDate(endDate),
  })
}
export const RetrospectiveAgencyHoursModal = ({ dispatch, item, triggerEvent }: Props) => {
  const closeModal = () => dispatch(hideModal(item.modalName))
  const handleOnExit = () => {
    triggerEvent(trackingEvents.CLOSE_BUTTON.CLICKED)
    closeModal()
  }
  const [activeDay, setActiveDay] = React.useState(new Date())
  const handleOnChange = (day: Date | undefined) => {
    if (day) {
      triggerEvent(trackingEvents.DATE.CHANGED, { day: datetimeToDate(day) })
      setActiveDay(day)
    }
  }
  const handleCancel = () => {
    triggerEvent(trackingEvents.CANCEL_BUTTON.CLICKED)
    closeModal()
  }
  const handleDownload = () => {
    fetchRetrospectiveAgencyHours(activeDay)
    triggerEvent(trackingEvents.DOWNLOAD_BUTTON.CLICKED, { day: datetimeToDate(activeDay) })
    closeModal()
  }

  return (
    <Modal
      focusTrapObserver
      focusTrapOptions={{ allowOutsideClick: true }}
      isOpen={item.isShown}
      onExit={handleOnExit}
      title="Download Retrospective agency hours CSV"
      sx={{ top: '-120px' }}
    >
      <ModalBody>
        <Text as="p" level={3}>
          Invoicing end date
        </Text>
        <Box sx={{ height: 50 }}>
          <Box sx={{ position: 'fixed', width: '550px', zIndex: 1000 }}>
            <DatePicker
              locale={enGB}
              selected={activeDay}
              onChange={handleOnChange}
              formFieldProps={{
                label: 'Select date',
                inputProps: {
                  placeholder: 'Select date',
                },
              }}
            />
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter sx={{ pl: 5, pr: 5 }}>
        <Button onClick={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleDownload}>Download</Button>
      </ModalFooter>
    </Modal>
  )
}

export default connect(state => ({
  item: getModal$(state.modals)('retrospectiveAgencyHoursModal'),
}))(withTrackingTriggerHoc(RetrospectiveAgencyHoursModal))
